import { NextWeekPrevOutput } from "../types";

export const calculateNextPrevWeek = (
  isPrevious: boolean,
  week: number,
  weekPart: number,
  hasSecondWeekPart: boolean,
  year: number
): NextWeekPrevOutput => {
  let newYear = year!;
  let newWeek = week!;
  let newWeekPart = 1;

  if (week === 1 && weekPart === 1 && isPrevious) newYear = year - 1;

  //Check if we need to go to the next or previous year
  if (!isPrevious) {
    const lastWeekOfYearNumber = getWeekNumber(new Date(`${year}-12-31`));

    if (week === 52) {
      return {
        week: 1,
        year: year + 1,
        weekPart: (hasSecondWeekPart || false) && weekPart! === 1 ? 2 : 1,
      };
    }
  }

  if (isPrevious) {
    if (week === 1 && weekPart === 1) {
      return {
        week: 52,
        weekPart: newWeekPart,
        year: newYear,
      };
    }

    const newW = newWeek + (isPrevious ? -1 : 1);

    const weekPartCalc = isWeekSplit(newYear, newW);

    if (weekPart !== 2) {
      newWeek = week! - 1;
      newWeekPart = weekPartCalc ? 2 : 1;
    }

    return {
      week: newWeek,
      weekPart: newWeekPart,
      year: newYear,
    };
  } else {
    if ((hasSecondWeekPart || false) && weekPart! === 1) {
      newWeekPart = 2;
    } else {
      newWeek = week! + 1;
    }

    return {
      week: newWeek,
      weekPart: newWeekPart,
      year: newYear,
    };
  }
};

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1)) as any;
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

function isWeekSplit(year, weekNumber) {
  // Step 1: Get the first day of the year (January 1st)
  const firstDayOfYear = new Date(year, 0, 1);

  // Step 2: Find the first Monday of the year by moving backwards
  let firstMonday = new Date(firstDayOfYear);

  // If the first day of the year is not a Monday, move backwards
  const daysToSubtract =
    firstMonday.getDay() === 0 ? 6 : firstMonday.getDay() - 1; // If it's Sunday (0), we subtract 6 days, otherwise, subtract days to Monday
  firstMonday.setDate(firstMonday.getDate() - daysToSubtract);

  // Step 3: Calculate the start date for the specified week number
  const startDate = new Date(firstMonday);
  startDate.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);

  // Step 4: Calculate the end date for the specified ISO week (Sunday)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6); // The week spans from Monday to Sunday

  // Step 5: Check if the week spans more than one month
  const isSplitAcrossMonths = startDate.getMonth() !== endDate.getMonth();

  return isSplitAcrossMonths;
}
