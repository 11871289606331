import { useEffect, useState } from "react";
import {
  getStayPleaseDailyCorrections,
  processStaypleaseDaily,
  getStayPleaseDailyCorrectionsRooms,
} from "../../services/stayPleaseService";
import {
  GetProjectOutput,
  GetStaypleaseDailyCorrectionsOutput,
  GetStaypleaseDailyCorrectionsRoomNumbersOutput,
} from "../../types";
import * as moment from "moment";
import PageTitle from "../components/Common/PageTitle";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Input,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { getProject } from "../../services/projectService";
import AppButton from "../components/Common/AppButton";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import { Stayplease_daily_status_types } from "../../utility/constants";
import DateToggler from "../components/Common/DateToggler";
import HeaderBanner from "../components/banners/HeaderBanner";
import React from "react";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import AppFormLabel from "../components/forms/AppFormLabel";
import { useParams } from "react-router-dom";

const StayPleaseCorrectionsSheet = () => {
  const { id } = useParams();

  const [stayPleaseDailyData, setStayPleaseDailyData] =
    useState<GetStaypleaseDailyCorrectionsOutput["result"]>();

  const [roomNumbersData, setRoomNumbersData] =
    useState<GetStaypleaseDailyCorrectionsRoomNumbersOutput["result"]>();
  const [saving, setSaving] = useState(false);
  const [comment, setComment] = useState("");

  const [project, setProject] = useState<GetProjectOutput>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProject(parseInt(id || 0));
  }, [id]);

  async function fetchProject(projectId: number) {
    const result = await getProject(projectId);

    if (result) {
      const projectRecord: GetProjectOutput = result.data.result;
      setProject(projectRecord);

      if (projectRecord.staypleaseDatabaseName)
        fetchStaypleaseDaily(id, moment().toDate(), false);
    }
  }

  async function fetchStaypleaseDaily(
    projectId: number,
    date: Date,
    refreshStaypleaseData: boolean
  ) {
    setLoading(true);
    const result = await getStayPleaseDailyCorrections(
      projectId,
      date.toISOString(),
      refreshStaypleaseData
    );

    const roomNumbersResult = await getStayPleaseDailyCorrectionsRooms(
      projectId,
      date.toISOString(),
      refreshStaypleaseData
    );
    if (roomNumbersResult) {
      setRoomNumbersData(roomNumbersResult.data.result);
    }

    if (result) {
      setStayPleaseDailyData(result.data.result);
    }

    setLoading(false);
  }

  const handleNextPrevDate = (isPrevious: boolean) => {
    if (isPrevious) {
      const newDate = moment(stayPleaseDailyData?.date)
        .clone()
        .endOf("day")
        .add(-1, "days");
      fetchStaypleaseDaily(id, newDate.toDate(), false);
    } else {
      const newDate = moment(stayPleaseDailyData?.date)
        .clone()
        .endOf("day")
        .add(1, "days");
      fetchStaypleaseDaily(id, newDate.toDate(), false);
    }
  };

  const handleSubmitForReview = () => {
    if (!comment) {
      Message(
        "Comment is required",
        `Please add a comment to submit the data`,
        "warningNoBtns"
      );
    } else {
      setSaving(true);
      Message(
        "Are you sure?",
        `Do you want to submit the daily data corrections for review?`,
        "warning"
      )
        .then(async (result) => {
          if (result.isConfirmed) {
            await processStaypleaseDaily({
              date: moment(stayPleaseDailyData!.date).toDate(),
              projectId: stayPleaseDailyData!.projectId,
              comment: comment,
              isOverridden: true,
              corrections: [
                {
                  name: "Checkout minus rooms",
                  value: roomNumbersData?.checkoutMinRooms,
                },
                {
                  name: "Checkout plus rooms",
                  value: roomNumbersData?.checkoutPlusRooms,
                },
                {
                  name: "Mandatory minus rooms",
                  value: roomNumbersData?.mandatoryMinRooms,
                },
                {
                  name: "Mandatory plus rooms",
                  value: roomNumbersData?.mandatoryPlusRooms,
                },
                {
                  name: "Stayover minus rooms",
                  value: roomNumbersData?.stayoverMinRooms,
                },
                {
                  name: "Stayover plus rooms",
                  value: roomNumbersData?.stayoverPlusRooms,
                },
              ],
              items: [
                {
                  staypleaseItemId: stayPleaseDailyData!.endOfDay.checkOut.id,
                  value: stayPleaseDailyData!.endOfDay.checkOut.value,
                },
                {
                  staypleaseItemId: stayPleaseDailyData!.endOfDay.mandatory.id,
                  value: stayPleaseDailyData!.endOfDay.mandatory.value,
                },
                {
                  staypleaseItemId: stayPleaseDailyData!.endOfDay.stayOver.id,
                  value: stayPleaseDailyData!.endOfDay.stayOver.value,
                },
                {
                  staypleaseItemId:
                    stayPleaseDailyData!.endOfDay.movementRooms.id,
                  value: stayPleaseDailyData!.endOfDay.movementRooms.value,
                },
                {
                  staypleaseItemId:
                    stayPleaseDailyData!.endOfDay.checkOutChecks.id,
                  value: stayPleaseDailyData!.endOfDay.checkOutChecks.value,
                },
              ],
            })
              .then(async (res) => {
                if (res.status === 200) {
                  setSaving(false);
                  Notify.success(`Data has been submitted for review!`);

                  const stayPleaseData = { ...stayPleaseDailyData! };

                  stayPleaseData.status =
                    Stayplease_daily_status_types.SendForReview;
                  stayPleaseData.isOverridden = true;

                  setStayPleaseDailyData(stayPleaseData);
                }
              })
              .catch((err) => {
                setSaving(false);

                if (err.response) {
                  Message(
                    err.response.data.error.message,
                    err.response.data.error.details,
                    "error"
                  );
                }
              });
          }
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  return (
    <>
      {project && !project.staypleaseDatabaseName && (
        <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm">
          <div className="lh-1">
            <h1 className="h6 mb-0 text-white lh-1">
              This project hasn't been properly configured. Contact your
              administrator.
            </h1>
            <small>
              You cannot see the data because the project is missing one or more
              configuration items.
            </small>
          </div>
        </div>
      )}

      {stayPleaseDailyData &&
        (stayPleaseDailyData.status ===
          (Stayplease_daily_status_types.IsDeclined &&
            !stayPleaseDailyData.isOverridden) ||
          (stayPleaseDailyData.status ===
            Stayplease_daily_status_types.SendForReview &&
            stayPleaseDailyData.isOverridden)) && (
          <HeaderBanner
            type="info"
            title="Corrections sheet has been submitted for approval"
            message="  You can no longer edit this corrections sheet because it has been submitted for approval."
          />
        )}

      {stayPleaseDailyData && !stayPleaseDailyData.readyForOverride && (
        <HeaderBanner
          type="info"
          title="Corrections sheet is not ready"
          message="  You have not submitted a daily, please submit a daily before you override it."
        />
      )}

      {stayPleaseDailyData &&
        (stayPleaseDailyData.status ===
          Stayplease_daily_status_types.IsApprovedByMOD ||
          stayPleaseDailyData.status ===
            Stayplease_daily_status_types.IsApprovedByHFCAreaManager ||
          stayPleaseDailyData.status ===
            Stayplease_daily_status_types.IsApprovedByHotelManager) && (
          <HeaderBanner
            type="info"
            title="Daily cannot be corrected"
            message="  The daily is not applicable for corrections"
          />
        )}

      {stayPleaseDailyData?.status ===
        Stayplease_daily_status_types.IsDeclined && (
        <HeaderBanner
          title="Daily declined"
          message={`This daily is declined, The following comment was added: ${
            stayPleaseDailyData.comments[
              stayPleaseDailyData.comments.length - 1
            ].value
          }`}
          type="danger"
        />
      )}

      {stayPleaseDailyData && project && (
        <>
          <PageTitle
            title={stayPleaseDailyData?.projectName}
            customStyles="mb-2"
          >
            <DatePickerFilter
              handleChange={(date) =>
                fetchStaypleaseDaily(id, moment(date).toDate(), true)
              }
              classNames="ms-2"
            />

            <button
              type="button"
              className="btn btn-xs btn-secondary ms-2"
              onClick={() =>
                fetchStaypleaseDaily(
                  id,
                  moment(stayPleaseDailyData.date).toDate(),
                  true
                )
              }
            >
              <FontAwesomeIcon icon={faRedo} />
            </button>
          </PageTitle>
        </>
      )}

      {stayPleaseDailyData?.date && stayPleaseDailyData.date && (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <DateToggler
                title={`${moment(stayPleaseDailyData!.date).format(
                  "dddd | DD-MM-yyyy | "
                )} week ${moment(stayPleaseDailyData!.date).isoWeek()}`}
                onNextDate={() => handleNextPrevDate(false)}
                onPreviousDate={() => handleNextPrevDate(true)}
              />
            </div>
          </div>
        </>
      )}

      {loading ? (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      ) : (
        <>
          <Row className="text-center pb-1">
            <h6>
              Please review the corrections made in Stayplease document and
              choose the date you wish to make corrections on using the date
              selector above.
            </h6>
          </Row>
          <Accordion className="mb-5" open="accordionItem1EndOfDay">
            <AccordionItem>
              <AccordionHeader targetId="accordionItem1EndOfDay">
                End of day (corrections)
              </AccordionHeader>
              <AccordionBody accordionId="accordionItem1EndOfDay">
                <Row>
                  <Col lg={8} sm={8}>
                    <h6>Category</h6>
                  </Col>
                  <Col lg={4} sm={4}>
                    <h6>Amount</h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Checkout" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.checkOut.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4" key="eod3">
                  <Col lg={8} sm={8}>
                    <Input value="Stayover" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.stayOver.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Mandatory clean" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.mandatory.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Movement rooms" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.movementRooms.value}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Check out checks" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={stayPleaseDailyData?.endOfDay.checkOutChecks.value}
                      disabled
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={12} className="text-end">
                    <h6 className="d-inline">End of day total: {"  "}</h6>
                    <h5 className="d-inline">
                      {stayPleaseDailyData?.endOfDay.endOfDayTotal.value}
                    </h5>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

          <Accordion className="mb-5" open="accordionItem1EndOfDay">
            <AccordionItem>
              <AccordionHeader targetId="accordionItem1EndOfDay">
                Original End of day
              </AccordionHeader>
              <AccordionBody accordionId="accordionItem1EndOfDay">
                <Row>
                  <Col lg={8} sm={8}>
                    <h6>Category</h6>
                  </Col>
                  <Col lg={4} sm={4}>
                    <h6>Amount</h6>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Checkout" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        stayPleaseDailyData?.originalEndOfDay.checkOut.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4" key="eod3">
                  <Col lg={8} sm={8}>
                    <Input value="Stayover" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        stayPleaseDailyData?.originalEndOfDay.stayOver.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={8} sm={8}>
                    <Input value="Mandatory clean" disabled />
                  </Col>
                  <Col lg={4} sm={4}>
                    <Input
                      value={
                        stayPleaseDailyData?.originalEndOfDay.mandatory.value
                      }
                      disabled
                    />
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>

          {roomNumbersData && (
            <Accordion open="accordionItem2" className="mb-5">
              <AccordionItem>
                <AccordionHeader targetId="accordionItem2">
                  Corrections selected rooms
                </AccordionHeader>
                <AccordionBody accordionId="accordionItem2">
                  <Row className="mb-4">
                    <Col lg={3} sm={3}>
                      <Input value="Checkout minus rooms" disabled />
                    </Col>
                    <Col lg={9} sm={9}>
                      <Input
                        value={roomNumbersData?.checkoutMinRooms}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={3} sm={3}>
                      <Input value="Checkout plus rooms" disabled />
                    </Col>
                    <Col lg={9} sm={9}>
                      <Input
                        value={roomNumbersData?.checkoutPlusRooms}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={3} sm={3}>
                      <Input value="Stayover minus rooms" disabled />
                    </Col>
                    <Col lg={9} sm={9}>
                      <Input
                        value={roomNumbersData?.stayoverMinRooms}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={3} sm={3}>
                      <Input value="Stayover plus rooms" disabled />
                    </Col>
                    <Col lg={9} sm={9}>
                      <Input
                        value={roomNumbersData?.stayoverPlusRooms}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={3} sm={3}>
                      <Input value="Mandatory minus rooms" disabled />
                    </Col>
                    <Col lg={9} sm={9}>
                      <Input
                        value={roomNumbersData?.mandatoryMinRooms}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col lg={3} sm={3}>
                      <Input value="Mandatory plus rooms" disabled />
                    </Col>
                    <Col lg={9} sm={9}>
                      <Input
                        value={roomNumbersData?.mandatoryPlusRooms}
                        disabled
                      />
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          )}

          {stayPleaseDailyData &&
            project &&
            stayPleaseDailyData.readyForOverride &&
            (stayPleaseDailyData.status ===
              (Stayplease_daily_status_types.IsDeclined &&
                stayPleaseDailyData.isOverridden) ||
              (stayPleaseDailyData.status ===
                Stayplease_daily_status_types.SendForReview &&
                !stayPleaseDailyData.isOverridden)) && (
              <>
                <Row>
                  <h4>Info</h4>
                  <Col sm={12}>
                    <AppFormLabel label="Comment" />
                    <Input
                      type="textarea"
                      onChange={(event) => setComment(event.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="text-end pt-5">
                  <Col>
                    <AppButton
                      loading={saving}
                      disabled={saving}
                      type="submit"
                      variant="submit"
                      title="Send for review"
                      buttonSize="lg"
                      onClick={() => {
                        handleSubmitForReview();
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}

          <Row>
            <h5>How to submit a correction</h5>
            <p>
              1. Login to Stayplease and add the correction tasks and please
              make sure to push them through the flow
            </p>
            <p>
              2. Make sure the daily you want to correct is submitted by the
              housekeeper
            </p>
            <p>3. Open this page and check the corrections</p>
            <p>
              4. If the corrections you made are correct, select the date you
              would like to correct
            </p>
            <p>
              5. Describe the reason you would like to submit the correction and
              press "Send for review"
            </p>
          </Row>
        </>
      )}
    </>
  );
};

export default StayPleaseCorrectionsSheet;
