import { useState } from "react";
import moment from "moment";

interface DateInfo {
  selectedYear: number | null;
  selectedWeek: number | null;
  weekPart: number | null;
}

const useWeekPart = () => {
  const [dateInfo, setDateInfo] = useState<DateInfo>({
    selectedYear: null,
    selectedWeek: null,
    weekPart: null,
  });

  const calculateWeekPart = async (selectedDate: any): Promise<DateInfo> => {
    const selectedMoment = moment(selectedDate);

    let selectedYear = selectedMoment.year();
    const selectedWeek = selectedMoment.isoWeek();
    const startOfWeek = selectedMoment.clone().startOf("isoWeek");
    let weekPart = 1; // Default to weekPart 1

    // If the ISO week is 1 and it's December (month 11),
    // we might be rolling over to the next year.
    if (selectedWeek === 1 && selectedMoment.month() === 11) {
      selectedYear += 1;
    }

    for (let day = 0; day <= 6; day++) {
      const dayMoment = startOfWeek.clone().add(day, "days");
      if (dayMoment.month() !== startOfWeek.month()) {
        // If the selected date is >= dayMoment date
        // AND the month matches, we are in part 2 of the week.
        weekPart =
          selectedMoment.date() >= dayMoment.date() &&
          selectedMoment.month() === dayMoment.month()
            ? 2
            : 1;
        break;
      }
    }

    const newDateInfo = {
      selectedYear,
      selectedWeek,
      weekPart,
    };

    // Update React state
    setDateInfo(newDateInfo);

    // Return the newly computed state
    return newDateInfo;
  };

  return [dateInfo, calculateWeekPart] as const;
};

export default useWeekPart;
