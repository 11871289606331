import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  additionalRequestItem,
  GetAdditionalRequestData,
  ProcessAdditionalRequestInput,
} from "../../types";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import {
  getAdditionalRequest,
  submitAdditionalRequestData,
} from "../../services/additionalRequestService";
import moment from "moment";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import AppTextInput from "../components/forms/AppTextInput";
import AppButton from "../components/Common/AppButton";
import Notify from "../../utility/notifications/Notify";
import Message from "../../utility/notifications/Message";
import { Additionalwork_status_types } from "../../utility/constants";
import HeaderBanner from "../components/banners/HeaderBanner";
import * as Yup from "yup";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import useWeekPart from "../../utility/hooks/useWeekPart";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";

const validationSchema = Yup.object().shape({
  additionalRequestCategories: Yup.array().of(
    Yup.object().shape({
      additionalRequestItems: Yup.array().of(
        Yup.object().shape({
          quantity: Yup.number().min(0).max(10000).label("Amount"),
        })
      ),
    })
  ),
});

const AdditionalHoursSheet = () => {
  const [loading, setLoading] = useState(false);
  const [dataEntryComplete, setDataEntryComplete] = useState(false);

  const [sendForReview, setSendForReview] = useState(false);

  const [dateInfo, calculateWeekPart] = useWeekPart();

  const { id } = useParams();

  const projectId = parseInt(id!);

  const [additionalHoursData, setAdditionalHoursData] =
    useState<GetAdditionalRequestData>();

  const { hasSecondWeekPart, weekPart, week, year } = additionalHoursData || {};

  async function fetchData(
    year: number,
    week: number,
    weekPart: number,
    projectId: number
  ) {
    setLoading(true);
    const result = await getAdditionalRequest(projectId, week, weekPart, year!);

    if (result) {
      setAdditionalHoursData(result.data.result);
    }

    setLoading(false);
  }

  useEffect(() => {
    const currentDate = moment();

    // If we're in the first ISO week, return the new year (next year)
    const year =
      currentDate.isoWeek() === 1 && currentDate.month() === 11
        ? currentDate.year() + 1
        : currentDate.year(); // Keep the current year otherwise

    fetchData(year, moment().isoWeek(), 1, projectId);
  }, []);

  const isEditingDisabled = () => {
    const dailyStatus = [
      Additionalwork_status_types.DeclinedByAreaManager,
      Additionalwork_status_types.DeclinedByHotelManager,
      Additionalwork_status_types.New,
    ].includes(additionalHoursData!.status);

    return !dailyStatus;
  };

  const handleSubmit = (data: GetAdditionalRequestData, { setSubmitting }) => {
    const additionalRequestInput: ProcessAdditionalRequestInput = {
      projectId: projectId,
      SendForReview: sendForReview,
      week: week!,
      weekPart: weekPart!,
      year: year!,
      items: [],
    };

    const flatRecords = data.additionalRequestCategories.flat();

    flatRecords.forEach((category) => {
      category.additionalRequestItems.forEach((item) => {
        additionalRequestInput.items.push({
          comment: item.comment,
          itemId: item.itemId,
          quantity: item.quantity,
        });
      });
    });

    submitAdditionalRequestData(additionalRequestInput)
      .then(() => {
        Notify.success(`Additional requests have been submitted for approval`);
        setDataEntryComplete(false);
        setSubmitting(false);
        fetchData(year!, week!, weekPart!, projectId);
      })
      .catch(() => {
        Message("Saving data failed");
      });
  };

  const handleNextPrevDate = (isPrevious: boolean) => {
    const newData = calculateNextPrevWeek(
      isPrevious,
      week!,
      weekPart!,
      hasSecondWeekPart!,
      year!
    );

    fetchData(newData.year, newData.week, newData.weekPart, projectId);
  };

  const handleDatePickerChange = async (newDate) => {
    const result = await calculateWeekPart(newDate);

    if (result) {
      fetchData(
        result.selectedYear!,
        result.selectedWeek!,
        result.weekPart!,
        projectId
      );
    }
  };

  const calculateCategoryTotal = (items) => {
    return items.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotal = (input: additionalRequestItem) => {
    // Calculate total based on room amounts
    if (input.isNumberOfRooms) {
      return `${input.quantity} room${input.quantity > 1 ? "s" : ""}`; // Handle plural for rooms
    }

    // Calculate total based on hours
    if (!input.isNumberOfRooms && !input.numberOfMinutes) {
      return `${input.quantity} hour${input.quantity > 1 ? "s" : ""}`; // Handle plural for hours
    }

    // Calculate total based on minutes and convert to hours
    const totalHours = (input.numberOfMinutes * input.quantity) / 60;
    return `${totalHours.toFixed(2)} hour${totalHours !== 1 ? "s" : ""} (${
      input.quantity
    } x ${input.numberOfMinutes} min)`;
  };

  const getAmountType = (input: additionalRequestItem) => {
    if (input.isNumberOfRooms) return "Amount of rooms";
    if (!input.isNumberOfRooms && !input.numberOfMinutes)
      return "Amount of hours";
    return "Quantity X minutes";
  };

  if (!additionalHoursData) {
    return <p>Er is geen data gevonden</p>;
  }

  const initialValues: GetAdditionalRequestData = additionalHoursData!;

  return (
    <>
      <PageTitle title="Additional request" customStyles="mb-2" />

      {additionalHoursData && (
        <>
          {additionalHoursData.status ===
            Additionalwork_status_types.SendForReview && (
            <HeaderBanner
              title="Additional request has been submitted for approval"
              message="You can no longer edit this additional request because it has been submitted for approval."
              type="info"
            />
          )}

          {(additionalHoursData.status ===
            Additionalwork_status_types.DeclinedByAreaManager ||
            additionalHoursData.status ===
              Additionalwork_status_types.DeclinedByHotelManager) && (
            <HeaderBanner
              title="The additional request has been denied"
              message={`The following comment was added: ${additionalHoursData.comment}`}
              type="danger"
            />
          )}

          {(additionalHoursData.status ===
            Additionalwork_status_types.ApprovedByAreaManager ||
            additionalHoursData.status ===
              Additionalwork_status_types.ApprovedByHotelManager) && (
            <HeaderBanner
              title="Additional request sheet approved"
              type="success"
            />
          )}
        </>
      )}

      <DateToggler
        title={
          hasSecondWeekPart || weekPart === 2 ? (
            <span className="mx-3">
              Additional request - week {week}.{weekPart}
            </span>
          ) : (
            <span className="mx-3">Additional request - week {week}</span>
          )
        }
        onNextDate={() => handleNextPrevDate(false)}
        handleDatePickerChange={(date) => handleDatePickerChange(date)}
        onPreviousDate={() => handleNextPrevDate(true)}
      />

      {loading ? (
        <Row className="text-center">
          <h4>Loading...</h4>
        </Row>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                {additionalHoursData?.additionalRequestCategories.map(
                  (category, ci) => (
                    <>
                      <Row>
                        <Col lg={12}>
                          <h4>{category.categoryName}</h4>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={3}>Item</Col>
                        <Col sm={3}>Amount</Col>
                        <Col sm={3}>Total</Col>
                        <Col sm={3}>Comments</Col>
                      </Row>

                      {category.additionalRequestItems.map(
                        (requestItem, ai) => (
                          <Row
                            className="mb-2"
                            key={`additionalRequestCategories[${ci}][${ai}]`}
                          >
                            <Col lg={3}>
                              <AppTextInput
                                type="text"
                                name={`additionalRequestCategories[${ci}].additionalRequestItems[${ai}].itemName`}
                                disabled
                              />
                            </Col>
                            <Col lg={3}>
                              <AppTextInput
                                type="number"
                                name={`additionalRequestCategories[${ci}].additionalRequestItems[${ai}].quantity`}
                                disabled={isEditingDisabled()}
                              />
                              <div className="form-text">
                                {getAmountType(requestItem)}
                              </div>
                            </Col>
                            <Col lg={3}>
                              <AppTextInput
                                type="text"
                                name={`additionalRequestCategories[${ci}].additionalRequestItems[${ai}].value`}
                                value={calculateTotal(
                                  values.additionalRequestCategories[ci]
                                    .additionalRequestItems[ai]
                                )}
                                disabled
                              />
                            </Col>
                            <Col lg={3}>
                              <AppTextInput
                                type="textarea"
                                name={`additionalRequestCategories[${ci}].additionalRequestItems[${ai}].comment`}
                                disabled={isEditingDisabled()}
                              />
                            </Col>
                          </Row>
                        )
                      )}

                      {isEditingDisabled() && (
                        <>
                          <hr />
                          <Row className="mb-2">
                            <Col className="fw-bolder" sm={3}>
                              {category.categoryName}
                            </Col>
                            <Col className="fw-bolder" sm={3}>
                              Total amount
                            </Col>
                            <Col className="fw-bolder" sm={3}>
                              {calculateCategoryTotal(
                                category.additionalRequestItems
                              )}
                            </Col>
                            <Col className="fw-bolder" sm={3}></Col>
                          </Row>
                        </>
                      )}
                    </>
                  )
                )}

                {additionalHoursData.comments && (
                  <>
                    <h5>Comments</h5>
                    {additionalHoursData.comments?.map((comment) => (
                      <MessageTimeLineItem
                        action={comment.type}
                        userName={comment.userName}
                        value={comment.value}
                        isAdditionalRequest={false}
                        creationTime={new Date(comment.creationTime)}
                      />
                    ))}
                  </>
                )}

                {!isEditingDisabled() && (
                  <>
                    <Row>
                      <Col className="mt-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="1"
                          onClick={(e) =>
                            setDataEntryComplete(e.target.checked)
                          }
                          id="dataEntryCorrect"
                        />
                        <label
                          htmlFor="dataEntryCorrect"
                          className="form-check-label ms-2"
                        >
                          To my knowledge, this form has been filled in
                          correctly
                        </label>
                      </Col>
                    </Row>

                    <Row className="text-end pt-5">
                      <Col>
                        <AppButton
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          onClick={(e) => {
                            setSendForReview(false);
                            handleSubmit(e);
                          }}
                          variant="save"
                          title="Save"
                          className="me-1"
                          buttonSize="lg"
                        />
                        <AppButton
                          loading={isSubmitting}
                          disabled={!dataEntryComplete || isSubmitting}
                          type="submit"
                          variant="submit"
                          onClick={(e) => {
                            setSendForReview(true);
                            handleSubmit(e);
                          }}
                          title="Send for review"
                          buttonSize="lg"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AdditionalHoursSheet;
