import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Row } from "reactstrap";
import PageTitle from "../components/Common/PageTitle";
import moment from "moment";
import {
  getExportMatrix,
  getMatrixData,
} from "../../services/hostDashboardService";
import AppButton from "../components/Common/AppButton";
import DateToggler from "../components/Common/DateToggler";
import saveAs from "file-saver";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import useWeekPart from "../../utility/hooks/useWeekPart";

const DailyMatrix = () => {
  const [data, setData] = useState<any[]>([]);

  const [week, setWeek] = useState<number>();
  const [year, setYear] = useState<number>();
  const [hasSecondWeekPart, setHasSecondWeekPart] = useState<boolean>();
  const [weekPart, setWeekPart] = useState<number>();
  const [dateInfo, calculateWeekPart] = useWeekPart();
  const [loading, setLoading] = useState(false);
  const [weekDescription, setWeekDescription] = useState<string>();
  const [exportingData, setExportingData] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // Get current date
      const currentDate = moment();

      // If we're in the first ISO week, return the new year (next year)
      const year =
        currentDate.isoWeek() === 1 && currentDate.month() === 11
          ? currentDate.year() + 1
          : currentDate.year(); // Keep the current year otherwise

      const result = await getMatrixData(year, moment().isoWeek(), 1);

      if (result) {
        setData(result.data.result);

        if (result.data.result[0]) {
          setHasSecondWeekPart(result.data?.result[0].hasSecondWeekPart);
          setWeekDescription(result.data.result[0].weekNumber);
        }
      }
      setYear(moment().year());
      setWeek(moment().isoWeek());
      setWeekPart(1);
      setLoading(false);
    }
    fetchData();
  }, []);

  async function fetchData(year: number, week: number, weekPart: number) {
    setLoading(true);
    const result = await getMatrixData(year, week, weekPart);
    if (result) {
      setData(result.data.result);

      if (result.data.result[0]) {
        setHasSecondWeekPart(result.data?.result[0].hasSecondWeekPart);
        setWeekDescription(result.data.result[0].weekNumber);
      }
    }
    setYear(year);
    setWeek(week);
    setWeekPart(weekPart);
    setLoading(false);
  }

  const handleNextPrevDate = (isPrevious: boolean) => {
    const newData = calculateNextPrevWeek(
      isPrevious,
      week!,
      weekPart!,
      hasSecondWeekPart!,
      year!
    );

    fetchData(newData.year!, newData.week, newData.weekPart);
  };

  const exportMatrix = () => {
    setExportingData(true);

    getExportMatrix(week!, weekPart!, year!).then((result) => {
      setExportingData(false);
      saveAs(new Blob([result.data]), `Export-matrix.xlsx`);
    });
  };

  return (
    <div>
      <>
        <PageTitle title={"Dashboard Matrix"} />

        <div className="d-flex justify-content-between">
          <div>
            <DateToggler
              title={
                hasSecondWeekPart || weekPart === 2 ? (
                  <span className="mx-3">
                    Weekly overview - week {week}.{weekPart}
                  </span>
                ) : (
                  <span className="mx-3">Weekly overview - week {week}</span>
                )
              }
              onNextDate={() => handleNextPrevDate(false)}
              onPreviousDate={() => handleNextPrevDate(true)}
            />
          </div>
          <div>
            <AppButton
              title="Export matrix"
              variant="export"
              className="btn btn-lg me-2"
              loading={exportingData}
              disabled={exportingData}
              onClick={() => {
                exportMatrix();
              }}
            />
          </div>
        </div>
        {loading && (
          <Row className="text-center">
            <h4>Loading...</h4>
          </Row>
        )}
        {data && (
          <Row>
            <DataTable
              // pagination

              responsive
              columns={[
                {
                  name: "Customer",
                  selector: (row) => row.tenantName,
                  sortable: true,
                },
                {
                  name: "HFC id",
                  selector: (row) => row.hfcId,
                  sortable: true,
                },
                {
                  name: "Project",
                  selector: (row) => row.projectName,
                  sortable: true,
                },
                {
                  name: "Daily",
                  selector: (row) => row.dailyStatus,
                  sortable: true,
                  cell: (row) => {
                    let bgColor = "";
                    switch (row.dailyStatus) {
                      case "HK":
                        bgColor = "#E8E8E8"; // Light grey
                        break;
                      case "Ready":
                        bgColor = "#B5E6A2"; // Light green
                        break;
                      case "MOD":
                        bgColor = "yellow"; // Yellow
                        break;
                      case "HM":
                        bgColor = "#4D93D9"; // Light blue
                        break;
                      default:
                        bgColor = ""; // Default background
                        break;
                    }
                    return (
                      <div style={{ backgroundColor: bgColor }}>
                        {row.dailyStatus}
                      </div>
                    );
                  },
                },
                {
                  name: "Additional request",
                  selector: (row) => row.additionalItemStatus,
                  sortable: true,
                  cell: (row) => {
                    let bgColor = "";
                    switch (row.additionalItemStatus) {
                      case "HK":
                        bgColor = "#E8E8E8"; // Light grey
                        break;
                      case "Ready":
                        bgColor = "#B5E6A2"; // Light green
                        break;
                      case "AM":
                        bgColor = "#FFE4C4"; // Bisque
                        break;
                      case "HM":
                        bgColor = "#4D93D9"; // Light blue
                        break;
                      default:
                        bgColor = ""; // Default background
                        break;
                    }
                    return (
                      <div style={{ backgroundColor: bgColor }}>
                        {row.additionalItemStatus}
                      </div>
                    );
                  },
                },
                {
                  name: "Is Exported To AFAS",
                  selector: (row) => row.isExportedToAfas,
                  sortable: true,
                  cell: (row) => {
                    let bgColor = row.isExportedToAfas ? "#B5E6A2" : "yellow";

                    return (
                      <div style={{ backgroundColor: bgColor }}>
                        {row.isExportedToAfas ? "Yes" : "No"}
                      </div>
                    );
                  },
                },
              ]}
              data={data}
            />
          </Row>
        )}
      </>
    </div>
  );
};

export default DailyMatrix;
